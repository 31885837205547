<template>
  <div class="mainCtn">
    <h1>FAQS</h1>
    <div class="question" v-for="(question, ind) in questions" :key="'question_' + ind">
      <b>{{ ind + 1 + ". " + question.title }}</b>
      <p>{{ question.answer }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Faqs",
  data() {
    return {
      questions: [
        { title: "¿Qué beneficios tiene usar BYP?", answer: "A completar por ivan" },
        { title: "¿Realmente necesito este producto o servicio?", answer: "A completar por ivan" },
        { title: "¿Cómo funciona?", answer: "A completar por ivan" },
        { title: "¿Tiene garantía?", answer: "A completar por ivan" }
      ]
    };
  }
};
</script>

<style scoped>
.question {
  text-align: initial;
  padding: 1rem;
}

.mainCtn{
    padding: 1rem 0;
}
</style>
